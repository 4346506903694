// import { atcb_action } from 'add-to-calendar-button';
import classNames from 'classnames';
import dayjs from 'dayjs';
// import { kebabCase } from 'lodash/fp';
// import { event as analyticEvent } from 'modules/Analytics';
import { type Event } from 'modules/App/models/Event';
import Price from 'modules/common/components/Price';
import { gql, useQuery } from 'modules/GraphQL';
import { getColorAndLabel } from 'modules/Marketing/components/ToolBadge';
import useUser from 'modules/User/hooks/useUser';
import dynamic from 'next/dynamic';
import React from 'react';
import Marquee from 'react-fast-marquee';
// import { MdCalendarViewMonth, MdClose } from 'react-icons/md';
const Calendar = dynamic(() => import('modules/common/components/Calendar'), { ssr: false });
type EventCalendarProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { formatMinutesToHoursAndMinutes } from 'utils/misc';
import useParticipant from '../hooks/useParticipant';
import { EventCard } from '@/lib/components/ui/event-card';
import { type EventParticipant } from '../models/EventParticipant';
import { Popover } from '@/lib/components/ui/popover';
import { PopoverContent, PopoverTrigger } from '@/lib/components/ui/popover';

dayjs.extend(utc);
dayjs.extend(timezone);

export const EVENT_FIELDS = gql`
  fragment AdminEventFields on Event {
    _id
    tool {
      name
      author
      authorUrl
      description
      duration
      type
      pricePro
      priceProId
      priceStandard
      priceStandardId
      priceProUrl
      priceStandardUrl
      url
    }
    participants {
      _id
      userId
      status
      createdAt
    }
    duration
    startAt
    endAt

    name
    url
    author
    authorUrl
    description
  }
`;

export const LIST_EVENTS = gql`
  query Events {
    events(sort: "-updatedAt") {
      pageInfo {
        count
        page
        limit
      }
      items {
        ...AdminEventFields
      }
    }
  }
  ${EVENT_FIELDS}
`;

const EventCalendar: React.FC<EventCalendarProps> = ({ className, ...props }) => {
  const { hasPermission } = useUser();
  const { data, refetch } = useQuery(LIST_EVENTS, { variables: {} });
  const { user } = useUser();
  const { participate, update: updateParticipation } = useParticipant();

  const { items = [] } = data?.events || {};
  const events = items as Event[];

  return (
    <div className={classNames('', className)} {...props}>
      <Calendar
        events={events}
        className="min-h-[80vh]"
        showAllEvents
        components={{
          event: ({ event: originalEvent }) => {
            const event = originalEvent as Event;
            const { startAt: start, endAt: end, tool } = event;

            const name = event.name || tool.name;
            const duration = event.duration || tool.duration || 0;
            const author = event.author || tool.author;
            const authorUrl = event.authorUrl || tool.authorUrl;
            const description = event.description || tool.description;
            const nbMaxParticipants = event.nbMaxParticipants || tool.nbMaxParticipants;
            // const url = event.url || tool.url;
            const priceStandard = tool.priceStandard || 0;
            const pricePro = tool.pricePro || 0;
            const buyProUrl = tool.priceProUrl;
            const buyStandardUrl = tool.priceStandardUrl;

            const myParticipation = event.participants?.find((participant: any) => participant.userId === user?.id);
            const status = myParticipation?.status;
            // const isPast = dayjs(start).isBefore(dayjs());

            const isParticipating = event.participants?.some((participant: any) => participant.userId === user?.id);

            return (
              <Popover key={event._id}>
                <PopoverTrigger asChild>
                  <div className="cursor-pointer">
                    {status === 'registered' ? (
                      <Marquee className="flex items-center gap-2" speed={30}>
                        <span className="font-bold inline-block mr-1">Enregistré</span>
                        {name}
                      </Marquee>
                    ) : (
                      <div className="">{name}</div>
                    )}

                    <small className="flex items-center justify-between gap-2">
                      <span>{dayjs(start).format('HH:mm')}</span>
                      <span className="font-bold">{formatMinutesToHoursAndMinutes(duration)}</span>
                    </small>
                  </div>
                </PopoverTrigger>
                <PopoverContent asChild>
                  <EventCard
                    className="calendar-popover shadow-md z-50"
                    title={name}
                    date={dayjs(start).format('dddd ll')}
                    time={`${dayjs(start).format('HH[h]mm')} ${end && dayjs(end).format('HH[h]mm')}`}
                    author={{
                      name: author,
                      url: authorUrl,
                      // avatar: '/placeholder.svg?height=32&width=32',
                    }}
                    initialStatus={status}
                    participants={event.participants?.length || 0}
                    maxParticipants={nbMaxParticipants || 0}
                    duration={duration}
                    description={description}
                    onStatusChange={async (status) => {
                      if (status === 'registered' && !isParticipating) {
                        await participate(event._id);
                      } else {
                        await updateParticipation((myParticipation as EventParticipant)._id, status);
                      }
                      await refetch();
                    }}
                  >
                    <footer className="flex gap-2">
                      {buyStandardUrl && (
                        <>
                          {buyStandardUrl && (
                            <span
                              className={classNames(' px-3 py-2 h-12 flex items-center gap-2', {
                                'line-through cursor-not-allowed': priceStandard === 20 && hasPermission('booster'),
                              })}
                            >
                              {priceStandard === 0 ? 'Gratuit' : <Price value={priceStandard} currency="EUR" className="text-lg font-bold"></Price>}
                              {priceStandard === pricePro ? <span className="badge badge-sm">PROMO</span> : null}
                            </span>
                          )}
                          {buyProUrl &&
                            (hasPermission('booster') ? (
                              <span className="px-3 py-2 h-12">
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                Pro
                              </span>
                            ) : (
                              <span className="px-3 py-2 h-12 flex items-center gap-2">
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                <div className="flex flex-col gap-1">
                                  {pricePro < priceStandard && (
                                    <span className="badge badge-sm badge-primary">
                                      <Price value={pricePro - priceStandard} currency="EUR" className=""></Price>
                                    </span>
                                  )}
                                </div>
                              </span>
                            ))}
                        </>
                      )}
                      {/* {!isPast && (
                        <button
                          className="btn btn-xl btn-white w-[280px] sm:w-auto"
                          onClick={() => {
                            analyticEvent({ action: `event-add-to-calendar-${_id}`, category: 'calendar', label: _id });
                            return atcb_action({
                              name,
                              description: description || tool.description || '',
                              startDate: dayjs(start).format('YYYY-MM-DD'),
                              startTime: dayjs(start).format('HH:mm'),
                              endTime: dayjs(end).format('HH:mm'),
                              options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
                              forceOverlay: true,
                              language: 'fr',
                              timeZone: 'Europe/Paris',
                              iCalFileName: kebabCase(name || ''),
                              location: url || window.location.href,
                            });
                          }}
                        >
                          <MdCalendarViewMonth />
                          Ajouter au calendrier
                        </button>
                      )} */}
                    </footer>
                  </EventCard>
                </PopoverContent>
              </Popover>
            );
          },
        }}
        dayPropGetter={(date) => {
          const today = dayjs().startOf('day');
          return {
            className: dayjs(date).isBefore(today) && !dayjs(date).isSame(today, 'day') ? 'rbc-past-day bg-gray-100' : '',
          };
        }}
        eventPropGetter={({ tool: { type }, startAt: start }: any) => {
          return {
            className: classNames(getColorAndLabel(type).color, {
              'opacity-50': dayjs(start).isBefore(dayjs()) && !dayjs(start).isSame(dayjs(), 'day'),
            }),
          };
        }}
        startAccessor={(event: any) => new Date(event.startAt)}
        endAccessor={(event: any) => new Date(event.endAt)}
      />
    </div>
  );
};

export default EventCalendar;
