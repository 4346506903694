import { formatPrice } from 'utils/misc';

interface IProps {
  value: number;
  currency?: string;
  options?: Intl.NumberFormatOptions;
  className?: string;
}

const Price = ({ value, currency = 'USD', options, ...props }: IProps) => {
  const priceStr = formatPrice({ value, currency, options });

  return <span {...props}>{priceStr}</span>;
};

export default Price;
