export const getType = (value: any) => {
  const match = Object.prototype.toString.call(value).match(/ (\w+)]/);
  return match && match[1].toLocaleLowerCase();
};
export const formatMinutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h`;
  }

  if (remainingMinutes > 0) {
    formattedTime += ` ${remainingMinutes}min`;
  }

  return formattedTime.trim();
};

export async function batchPromise<T>(tasks: Promise<T>[], batchSize: number): Promise<T[]> {
  console.log(`Starting batch processing: ${tasks.length} in batch of ${batchSize}`);
  const results: T[] = [];

  for (let i = 0; i < tasks.length; i += batchSize) {
    console.log(`Processing batch starting at index: ${i}`);
    const batch = tasks.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch);
    console.log(`Batch processed successfully: ${i} to ${i + batchSize - 1}`);
    results.push(...batchResults);
  }

  console.log(`All batches processed successfully. Total results: ${results.length}`);
  return results;
}

export const formatPrice = ({ value, currency = 'USD', options = {} }: any) => {
  const lang = 'fr-FR';

  if (value === undefined || value === null || Math.abs(value) === Infinity || isNaN(value)) {
    return '-';
  }

  const allOptions: any = {
    style: 'currency',
    currency,
    ...options,
  };

  if (value <= 0.01 && value >= -0.01) {
    // In cas value is 0, we display no decimals
    allOptions.minimumFractionDigits = 0;
    allOptions.maximumFractionDigits = 0;
  }

  try {
    return new Intl.NumberFormat(lang, allOptions).format(value).replace('US', '');
  } catch (err) {
    console.error(`Error formatting price with value ${value} and currency ${currency} for lang ${lang}`);
    console.error(err);
    return 'NaN';
  }
};

export const removeEmojis = (str: string) => str.replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
export const capitalizeName = (name: string) => name.toLowerCase().replace(/(^|\s|-)\p{L}/gu, (match: string) => match.toUpperCase());
export const sanitizeName = (name: string) => removeEmojis(capitalizeName(name));
