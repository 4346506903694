import { gql, useMutation } from 'modules/GraphQL';

const PARTICIPATE_EVENT = gql`
  mutation ParticipateEvent($input: createEventParticipantInput!) {
    createEventParticipant(input: $input) {
      item {
        _id
        userId
        createdAt
      }
    }
  }
`;

const UPDATE_PARTICIPATION = gql`
  mutation UpdateParticipation($_id: ID!, $input: updateEventParticipantInput!) {
    updateEventParticipant(_id: $_id, input: $input) {
      item {
        _id
      }
    }
  }
`;

const useParticipant = () => {
  const [participateEvent, { loading: participateLoading }] = useMutation(PARTICIPATE_EVENT);
  const [updateParticipation, { loading: updateLoading }] = useMutation(UPDATE_PARTICIPATION);

  const participate = async (eventId: string) => {
    try {
      const result = await participateEvent({ variables: { input: { eventId } } });
      return result;
    } catch (error) {
      throw error;
    }
  };

  const update = async (eventParticipantId: string, status: string) => {
    try {
      const result = await updateParticipation({
        variables: { _id: eventParticipantId, input: { status } },
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  return {
    participate,
    update,
    participateLoading,
    updateLoading,
    loading: participateLoading || updateLoading,
  };
};

export default useParticipant;
