'use client';

import { useState } from 'react';
import { CalendarClock, Check, Users } from 'lucide-react';

import { Badge } from 'lib/components/ui/badge';
import { Button } from 'lib/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'lib/components/ui/card';
import { Separator } from 'lib/components/ui/separator';
import { type EventParticipant } from 'modules/App/models/EventParticipant';
import { cn } from 'lib/utils';
import { MdClose } from 'react-icons/md';
import Markdown from 'lib/components/ui/markdown';
import { formatMinutesToHoursAndMinutes } from 'utils/misc';

interface EventCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  date: string;
  time: string;
  author: {
    name: string;
    url?: string;
    avatar?: string;
  };
  description: string;
  duration: number;
  participants: number;
  maxParticipants: number;
  initialStatus?: EventParticipant['status'];
  children?: React.ReactNode;
  onClose?: () => void;
  onStatusChange?: (status: EventParticipant['status']) => void;
}

export const EventCard: React.FC<EventCardProps> = ({
  title,
  date,
  time,
  author,
  participants: initialParticipants,
  maxParticipants,
  initialStatus = null,
  children,
  className,
  duration,
  onClose,
  description,
  onStatusChange,
  ...props
}) => {
  const [status, setStatus] = useState<EventParticipant['status'] | undefined>(initialStatus || undefined);
  const [participants, setParticipants] = useState(initialParticipants);

  const handleRsvp = (newStatus: EventParticipant['status']) => {
    setStatus(newStatus);
    if (newStatus === 'registered') {
      setParticipants(participants + 1);
    } else {
      setParticipants(participants - 1);
    }
    onStatusChange?.(newStatus);
  };

  const isAtCapacity = participants >= maxParticipants && maxParticipants > 0;

  return (
    <Card className={cn('w-full relative max-w-md overflow-hidden', className)} {...props}>
      {onClose && (
        <button onClick={onClose} className="absolute top-2 right-2 text-muted-foreground hover:text-foreground">
          <MdClose className="h-4 w-4" />
        </button>
      )}
      <CardHeader className="pb-3">
        <div className="flex items-start justify-between">
          <CardTitle className="text-xl font-semibold">{title}</CardTitle>
          {isAtCapacity && <Badge variant="destructive">COMPLET</Badge>}
        </div>
        <div className="flex items-center gap-2 text-sm text-muted-foreground">
          <CalendarClock className="h-4 w-4" />
          <span>
            {date} • {time}
          </span>
        </div>
      </CardHeader>
      <CardContent className="py-3">
        <div className="flex items-center gap-3 mb-0">
          <span className="text-sm font-medium w-20">Organisé par</span>
          <span className="text-sm text-muted-foreground">
            <a href={author.url} target="_blank" rel="noopener noreferrer" className="link">
              {author.name}
            </a>
          </span>
        </div>
        <div className="flex items-center gap-3 mb-4">
          <span className="text-sm font-medium w-20">Durée</span>
          <span className="text-sm text-muted-foreground">{formatMinutesToHoursAndMinutes(duration)}</span>
        </div>
        {participants > 0 ? (
          <div className="flex items-center gap-2">
            <Users className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm">
              <span className="font-medium">{participants}</span>
              {maxParticipants > 0 ? (
                <>
                  <span className="text-muted-foreground"> sur </span>
                  <span className="font-medium">{maxParticipants}</span>
                  <span className="text-muted-foreground"> participants</span>
                </>
              ) : (
                <span className="text-muted-foreground"> participant{participants > 1 ? 's' : ''}</span>
              )}
            </span>
          </div>
        ) : maxParticipants > 0 ? (
          <span className="text-muted-foreground">
            <span className="font-medium">{participants}</span> places
          </span>
        ) : null}
        <div className="prose prose-sm mt-5">
          <Markdown>{description || ''}</Markdown>
        </div>
        {children}
      </CardContent>
      <Separator />

      <CardFooter className="pt-3">
        <div className="flex w-full gap-2">
          {isAtCapacity && status !== 'registered' ? (
            <Button variant="outline" size="sm" className="flex-1" disabled>
              Désolé, plus de place disponible
            </Button>
          ) : status === 'registered' ? (
            <Button variant="destructive" size="sm" className="flex-1" onClick={() => handleRsvp('canceled')}>
              J’annule
            </Button>
          ) : status === 'canceled' || !status ? (
            <Button variant="primary" size="sm" className={'flex-1'} onClick={() => handleRsvp('registered')} disabled={isAtCapacity} stopPropagation>
              <Check className={cn('mr-1 h-4 w-4 text-muted-foreground')} />
              J’y vais
            </Button>
          ) : (
            <Button
              variant="outline-destructive"
              size="sm"
              className={'flex-1'}
              onClick={() => handleRsvp('registered')}
              disabled={isAtCapacity}
              stopPropagation
            >
              <Check className={cn('mr-1 h-4 w-4 text-green-600')} />
              J’y vais
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};
